@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  background-color: #f0f0f0;
}

.login-container {
  display: flex;
  position:relative;
  width: 900px;
  height: 600px;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.login-container {
  position: relative; /* Ensure the container is positioned relative for the accent line */
}

.accent-line {
  width: 100%;
  height: 5px; /* Adjust the height as needed */
  background: linear-gradient(
    to right,
    var(--sidebar-bg) 50%,
    #24aae1 50%,
    #24aae1 75%,
    var(--tb-header) 75%
  );
  position: absolute;
  bottom: -17px; /* Position it at the bottom of the container */
  left: 0;
  padding: 7px;
}

.logo-section {
  width: 50%;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-section img {
  max-width: 80%;
}
#login-title {
  color: var(--sidebar-bg);
  display: flex;
  flex-direction: row; /* Arrange children in a row */
  align-items: center; /* Align items vertically centered */
  justify-content: center; /* Center items horizontally */
}

#login-title h2, #login-title p {
  margin: 0 5px; /* Add some margin between the elements */
}

.login-section {
  width: 50%;
  background: var(--sidebar-bg);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.login-section h1 {
  margin-bottom: 20px;
}
.login-section h2 {
  margin-bottom: 20px;
  text-align: center;
}

.login-section form {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

.login-section label {
  margin-bottom: 5px;
}

.login-section input[type="text"],
.login-section input[type="password"] {
  margin-bottom: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.login-section .remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.login-section .remember-me input {
  margin-right: 10px;
}

.login-section button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--tb-header);
  color: var(--sidebar-bg);
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}
.login-section button.change-password {
  background-color: #24aae1;
}
.login-section button:hover {
  background-color: var(--light-primary);
}

.change-password-container{
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

.change-password-container input[type="password"],.change-password-container input[type="text"] {
  margin-bottom: -10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.change-password-container h3{
  text-align: center;
  margin-bottom: 15px;
}
.change-password-container #btn-back-login{
  width: 50%;
  margin: auto;
  background-color: #24aae1;
  color:var(--sidebar-bg);
}