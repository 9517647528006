.navbar-gradient {
    background: linear-gradient(
      to right,
      #135581 50%,
      #24aae1 50%,
      #24aae1 75%,
      #efcb13 75%
    );
    /* padding: 10px; Adjust padding as needed */
    display: flex;
    align-items: center;
  }